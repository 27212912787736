import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import './reviewsStyles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Autoplay, Lazy } from 'swiper';

import { NEW_SLIDE_DATA } from './NEW_SLIDE_DATA';
import { wait } from '../../helpers/utils';
import LinkButton from '../LinkButton';
import {
  CREATE_CARD_LINK,
  EXPLORE_GIFTS,
  HOWITWORKS_LINK,
  SEND_FREE_GROUPCARD_LINK,
  SLACK_INSTALL_LINK,
} from '../../constants/links';

const LargeImg = styled.img`
  max-width: 600px;
  opacity: 1;
  transition: opacity ease 0.5s;

  &[data-src] {
    opacity: 0;
  }
`;

const FadedContainerLeft = styled.div`
  position: absolute;
  width: 30%;
  top: 0%;
  left: -10px;
  background-image: linear-gradient(
    to right,
    rgba(255, 253, 243, 0.9) 65%,
    rgba(255, 253, 243, 0.6),
    rgba(255, 253, 243, 0)
  );
  z-index: 3;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 35%;
    background-image: linear-gradient(
      to right,
      rgba(255, 253, 243, 1) 45%,
      rgba(255, 253, 243, 0.6),
      rgba(255, 253, 243, 0)
    );
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 35%;
    background-image: linear-gradient(
      to right,
      rgba(255, 253, 243, 1) 35%,
      rgba(255, 253, 243, 0.6),
      rgba(255, 253, 243, 0)
    );
  }
`;

const FadedContainerRight = styled.div`
  position: absolute;
  width: 45%;
  top: 10%;
  right: -35%;
  background-image: linear-gradient(
    to left,
    rgba(255, 253, 243, 0.9) 65%,
    rgba(255, 253, 243, 0.6),
    rgba(255, 253, 243, 0)
  );
  z-index: 3;
  height: 80%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 32%;
    right: -8%;
    height: 80%;
    background-image: linear-gradient(
      to right,
      rgba(255, 253, 243, 0),
      rgba(255, 253, 243, 0.6),
      rgba(255, 253, 243, 1) 55%
    );
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 34%;
    right: -8%;
    background-image: linear-gradient(
      to right,
      rgba(255, 253, 243, 0),
      rgba(255, 253, 243, 0.6),
      rgba(255, 253, 243, 1) 55%
    );
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 8px;
  flex-direction: row;

  img {
    width: 20px;
    height: 20px;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 12px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 20px;

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 0 6px;

  h6 {
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
  }

  p {
    font-weight: 400;
    font-size: 6px;
    line-height: 10px;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 15px;

    h6 {
      font-size: 12px;
      line-height: 16px;
    }

    p {
      font-weight: 400;
      font-size: 9px;
      line-height: 14px;
    }
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 15px;

    h6 {
      font-size: 15px;
      line-height: 18px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const ReviewContainer = styled.div<{ show: boolean }>`
  // display: ${(props) => (props.show ? 'flex' : 'none')} !important;
  display: flex !important;
  opacity: ${(props) => (props.show ? 1 : 0)};
  align-items: flex-start;
  flex-direction: row;
  position: absolute;
  padding: 15px 0;
  transition: opacity ease 0.5s;

  img {
    width: 13px;
  }

  p {
    font-size: 8px;
    padding-left: 5px;
    line-height: 10px;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    img {
      width: 32px;
    }

    p {
      font-size: 11px;
      padding-left: 10px;
      line-height: 14px;
    }
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    img {
      width: 32px;
    }

    p {
      font-size: 14px;
      padding-left: 15px;
      line-height: 16px;
    }
  }
`;

const SlideContent = styled.span<{ show: boolean }>`
  display: block;
  width: 100%;
  @media (min-width: 768px) {
    min-width: 250px;
  }
  position: relative;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity ease 0.5s;
`;

const Actions = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
  }
`;

const GroupGiftLink = styled(LinkButton)`
  margin: 0 auto 8px auto;
  width: 100%;
  font-weight: 500;
  max-width: 187px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 18px 15px 0 0;
    max-width: 184px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 18px 15px 0 0;
    max-width: 240px;
  }
  &:hover {
    background-color: #e5a619;
  }
`;

const ExploreGiftsLink = styled(LinkButton)`
  background: ${(props) => props.theme.colors.lightPeach};
  color: ${(props) => props.theme.colors.blackText};
  width: 100%;
  font-weight: 500;
  margin: 0 auto;
  max-width: 187px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 18px 0 0 0;
    max-width: 136px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 18px 0 0 0;
    max-width: 176px;
  }
`;

const Ticker = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 12px 0 0 0;
  width: 100%;

  strong {
    padding: 0 4px 0 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 240px;
  }
`;

export interface Slide {
  id: string;
  loaded?: boolean;
  largeImage: string;
  description: {
    image: string;
    alt: string;
    title: string;
    paragraph: string;
    companyAlt: string;
  };
  review: {
    image: string;
    paragraph: string;
  };
}

export type NewSlide = {
  image: {
    src: string;
    alt: string;
  };
  review: {
    user: {
      avatarSrc: string;
      name: string;
    };
    copy: string;
  } | null;
};

const NEW_SLIDE_WITH_STATE = () => {
  return NEW_SLIDE_DATA.map((slide, index) => {
    return {
      ...slide,
      id: index.toString(),
      loaded: true,
    };
  });
};

const StyledSwiper = styled(Swiper)<{ paddingBottom: number }>`
  padding-top: 10px !important;
  margin-top: 60px !important;
  margin-bottom: 10px !important;
  padding-bottom: ${(props) => props.paddingBottom}px !important;
`;

type Props = {
  showReviews: boolean;
  showCta: boolean;
  isSlack?: boolean;
};

export default function ReviewsSlider({ showReviews, showCta, isSlack = false }: Props) {
  const [slides] = useState(() => NEW_SLIDE_WITH_STATE());
  const [loaded, setLoaded] = useState(false);

  const [x, setActiveIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  const paddingBottom = showReviews ? 70 : 0;

  return (
    <>
      <StyledSwiper
        paddingBottom={paddingBottom}
        onActiveIndexChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        effect={'coverflow'}
        grabCursor={false}
        // centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        slidesPerView={3.1}
        spaceBetween={15}
        loop={true}
        watchSlidesProgress={true}
        lazy={{
          loadPrevNext: true,
        }}
        modules={[Autoplay, Lazy]}
        breakpoints={{
          768: {
            spaceBetween: 20,
            slidesPerView: 3.1,
          },
          1025: {
            spaceBetween: 30,
            slidesPerView: 3.3,
          },
        }}
      >
        <FadedContainerLeft></FadedContainerLeft>
        {slides.map((slide, index) => (
          <SwiperSlide key={slide.id}>
            <SlideContent show={slide.loaded || false}>
              <LargeImg data-src={slide.image.src} alt={slide.image.alt} className='swiper-lazy' />
              {/* <DescriptionContainer>
                <img src={slide.description.image} alt={slide.description.title} />
                <TextContainer>
                  <h6>{slide.description.title}</h6>
                  <p>{slide.description.paragraph}</p>
                </TextContainer>
              </DescriptionContainer> */}
              {slide.review && showReviews && (
                <ReviewContainer show={index === x - 3 && loaded}>
                  <img src={slide.review.user.avatarSrc} alt={slide.review.user.name} />
                  {slide.review.copy && <p>{slide.review.copy}</p>}
                </ReviewContainer>
              )}
            </SlideContent>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <FadedContainerRight></FadedContainerRight>

      {showCta && (
        <>
          {isSlack ? (
            <Actions>
              <GroupGiftLink
                href={SLACK_INSTALL_LINK}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <svg
                  style={{ marginRight: 10 }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='24' height='24' rx='6' fill='white' />
                  <path
                    d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M9.63735 6.77098C8.58153 6.77098 7.75195 5.91623 7.75195 4.88549C7.75195 3.85476 8.60666 3 9.63735 3C10.668 3 11.5227 3.85476 11.5227 4.88549V6.77098H9.63735Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M9.63658 7.72607C10.6924 7.72607 11.522 8.58083 11.522 9.61157C11.522 10.6423 10.6673 11.4971 9.63658 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.63658Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M16.2747 9.63693C16.2747 10.6928 15.42 11.5224 14.3893 11.5224C13.3586 11.5224 12.5039 10.6677 12.5039 9.63693V4.88549C12.5039 3.82962 13.3586 3 14.3893 3C15.42 3 16.2747 3.85476 16.2747 4.88549V9.63693Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3332 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M9.61196 6.77098C8.55614 6.77098 7.72656 5.91623 7.72656 4.88549C7.72656 3.85476 8.58127 3 9.61196 3C10.6426 3 11.4973 3.85476 11.4973 4.88549V6.77098H9.61196Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M9.61144 7.72607C10.6673 7.72607 11.4968 8.58083 11.4968 9.61157C11.4968 10.6423 10.6421 11.4971 9.61144 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.61144Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M16.2493 9.63693C16.2493 10.6928 15.3946 11.5224 14.3639 11.5224C13.3081 11.5224 12.4785 10.6677 12.4785 9.63693V4.88549C12.4785 3.82962 13.3332 3 14.3639 3C15.4197 3 16.2493 3.85476 16.2493 4.88549V9.63693Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3081 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                    fill='#ECB22E'
                  />
                </svg>
                Try illume For Free
              </GroupGiftLink>
              <Ticker>No credit card required</Ticker>
            </Actions>
          ) : (
            <Actions>
              <GroupGiftLink href={SEND_FREE_GROUPCARD_LINK}>Create a group card</GroupGiftLink>
              <ExploreGiftsLink href={EXPLORE_GIFTS}>Explore all gifts</ExploreGiftsLink>
            </Actions>
          )}
        </>
      )}
    </>
  );
}
