import React, { FC } from 'react';
import Root from 'react-fast-marquee';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { theme } from '../styles/theme';
import HtmlRenderer from './html-renderer/HtmlRenderer';

import gifLogo from './gif.logo.svg';

const Layout = styled.section`
  align-self: center;
  margin: 0 -24px;
  padding: 6px 4px;
  width: 100vw;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 6px 0;
  }
`;

const ImageElementContainer = styled.div<{ url: string }>`
  margin: 0 8px;
  height: 176px;
  width: 280px;
  border-radius: 8px;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.73%, rgba(0, 0, 0, 0.7) 90.52%),
    url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .text {
    position: absolute;
    bottom: 16px;
    left: 16px;
    color: ${(props) => props.theme.colors.lightPeach2};
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
  .avatar {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    img {
      max-width: 30px;
      width: 100%;
      display: block;
      border-radius: 50%;
    }
    .name {
      color: ${(props) => props.theme.colors.white};
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const NoteElementContainer = styled.div<{
  background: React.CSSProperties['background'];
  textColor: React.CSSProperties['color'];
}>`
  margin: 0 8px;
  position: relative;
  height: 176px;
  width: 280px;
  background: ${(props) => props.background};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  padding-top: 64px;
  .text {
    color: ${(props) => props.textColor};
    text-align: center;
    font-family: brandon-grotesque;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    p {
      color: inherit;
    }
  }
  .avatar {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    img {
      max-width: 30px;
      width: 100%;
      display: block;
      border-radius: 50%;
    }
    .name {
      color: ${(props) => props.theme.colors.white};
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

// components
const NoteElement: FC<{
  background?: string;
  text: string;
  textColor?: string;
  user: {
    name: string;
    img: string;
  };
}> = ({ background = theme.colors.lightOlive, textColor = theme.colors.gray60, text, user }) => {
  // different background for brand
  // different text render for note vs brand
  // "Gift"
  return (
    <NoteElementContainer background={background} textColor={textColor}>
      <HtmlRenderer className='text' html={`<p><q>${text}</q></p>`} />
      <div className='avatar'>
        <img src={user.img} alt={user.name} />
        <p className='name'>{user.name}</p>
      </div>
    </NoteElementContainer>
  );
};

const ImageElement: FC<{
  background?: string;
  text?: string;
  alt: string;
  user?: IUser;
}> = ({ background = theme.colors.lightOlive, text, alt, user }) => {
  return (
    <ImageElementContainer role='img' title={alt} url={background}>
      {text && <p className='text'>{text}</p>}
      {user && (
        <div className='avatar'>
          <img src={user.image} alt={user.name} />
          <p className='name'>{user.name}</p>
        </div>
      )}
    </ImageElementContainer>
  );
};

const GifElementContainer = styled.div`
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 8px;
  min-width: 200px;
  background: ${(props) => props.theme.colors.gray40};
  border-radius: 8px;
  overflow: hidden;
  .gif {
    border-radius: 8px;
    height: 100%;
    display: block;
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .avatar {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    img {
      max-width: 30px;
      width: 100%;
      display: block;
      border-radius: 50%;
    }
    .name {
      color: ${(props) => props.theme.colors.white};
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const GifElement = ({ user, gifUrl, alt = '' }: { user: IUser; gifUrl: string; alt: string }) => (
  <GifElementContainer>
    <div
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 43.73%, rgba(0, 0, 0, 0.2) 90.52%)`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    ></div>
    <img src={gifUrl} className='gif' alt={alt} />
    <img src={gifLogo} alt='gif' className='logo' />
    <div className='avatar'>
      <img src={user.image} alt={user.name} />
      <p className='name'>{user.name}</p>
    </div>
  </GifElementContainer>
);

type IUser = {
  name: string;
  image: string;
};
export interface MarqueeProps {
  direction: 'left' | 'right' | undefined;
  data: (
    | {
        type: 'image';
        image: string;
        text?: string;
        user?: IUser;
        alt: string;
      }
    | { type: 'gif'; url: string; alt: string; user: IUser }
    | { type: 'note'; user: IUser; palette: { main: string; contrastText: string }; html: string }
  )[];
}

const StyledMarquee = styled(Root)`
  .marquee {
    // column-gap: 16px;
  }
`;

export const MarqueeV2: FC<MarqueeProps> = ({ direction, data }) => {
  return (
    <Layout>
      <StyledMarquee direction={direction} gradient={false} loop={0} speed={10}>
        {[...data, ...data].map((gift, index) => {
          return match(gift)
            .with({ type: 'note' }, (data) => {
              return (
                <NoteElement
                  user={{
                    img: data.user.image,
                    name: data.user.name,
                  }}
                  background={data.palette.main}
                  textColor={data.palette.contrastText}
                  key={index}
                  text={data.html}
                />
              );
            })
            .with({ type: 'gif' }, (data) => (
              <GifElement
                user={{ image: data.user.image, name: data.user.name }}
                alt={data.alt}
                gifUrl={data.url}
                key={index}
              />
            ))
            .with({ type: 'image' }, (dat) => (
              <ImageElement user={dat.user} alt={dat.alt} text={dat.text} background={dat.image} />
            ))
            .exhaustive();
        })}
      </StyledMarquee>
    </Layout>
  );
};
