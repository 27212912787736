import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
`;

const Word = styled.span<{ isVisible: boolean; color: string }>`
  color: ${(props) => props.color};
  display: inline-block;
  text-align: left;
  animation: ${(props) => (props.isVisible ? slideIn : slideOut)} 0.6s;
  left: 0;
`;

const WordContainer = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  display: inline-block;
`;

interface Props {
  color: string;
  rotationSpeed: number;
  words: string[];
}
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const WordSlider: FC<Props> = ({ color, words }) => {
  const [visibleIndex, setVisibleIndex] = useState<number>(0);
  const [toggleVisible, setToggleVisible] = useState<boolean>(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((visibleIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [visibleIndex, words.length]);

  const currentWord = words[visibleIndex];

  const trigger = () => {
    setToggleVisible((prev) => !prev);
    wait(300).then(() => {
      setToggleVisible((prev) => !prev);
    });
  };

  useEffect(() => {
    trigger();
  }, [currentWord]);

  return (
    <WordContainer color={color}>
      <Word color={color} onClick={trigger} isVisible={toggleVisible}>
        {currentWord}
      </Word>
    </WordContainer>
  );
};

export default WordSlider;
