import React, { FC, HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';

import LinkButton from '../../components/LinkButton';

import wave from './wave.svg';
import { Action } from '../pricing/components/PricingCard';
import { feature_data as FEATURE_COPIES } from '../../data/feature.data';
import { MarqueeProps, MarqueeV2 } from '../../components/MarqueV2';

interface FeatureCopies {
  img: string;
  title: string;
  description: string;
}

const Layout = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
`;

const Heading = styled.div`
  color: ${(props) => props.theme.colors.gray100};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 auto 24px auto;
  padding: 24px 0 0 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    max-width: 545px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 800px;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 24px 36px 24px;
  text-align: center;
  white-space: nowrap;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 24px;
    margin: 0 24px 36px 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    padding: 8px 0 0 0;
    margin: 0 24px 46px 24px;
  }
`;

const PrimaryCta = styled(LinkButton)`
  min-width: 200px;
  @media (min-width: 900px) {
    min-width: 256px;
  }
  font-weight: normal;
  &:hover {
    background-color: #e5a619;
  }
`;

const SecondaryCta = styled(LinkButton)`
  font-weight: 500;
  min-width: 200px;
  @media (min-width: 900px) {
    min-width: 256px;
  }
  color: ${(props) => props.theme.colors.gray100};
  background-color: #f5eed6;
  &:hover {
    background-color: #efe6ce;
  }
`;

const CtaContainer = styled.div`
  align-self: center;
  display: flex;
  column-gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 12px;
  margin-bottom: -48px;
  margin-top: 20px;
  flex-direction: column;
  @media (min-width: 900px) {
    margin-bottom: 0;
    flex-direction: row;
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;
`;

const WaveContainer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  bottom: 15px;
  display: flex;
  align-items: flex-end;
  overflow-y: hidden;
  height: 1000px;
  background-color: ${(props) => props.theme.colors.lightPeach4};
  @media (min-width: 1000px) {
    background-color: unset;
    height: 500px;
  }
`;

const Copies = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 48px 24px;
  justify-content: space-around;
  row-gap: 64px;
  column-gap: 12px;
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  align-items: center;
  padding: 0 12px;
  img {
    max-width: 30px;
    display: block;
    @media (min-width: 900px) {
      display: none;
    }
  }
  @media (min-width: 900px) {
    text-align: left;
    padding: 0;
    align-items: flex-start;
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    @media (min-width: 900px) {
      font-size: 28px;
      line-height: 34px;
    }
    font-weight: 420;
  }
  .description {
    font-size: 16px;
    line-height: 22px;
    @media (min-width: 900px) {
      font-size: 18px;
      line-height: 24px;
    }
    color: ${(props) => props.theme.colors.textColorCopy};
    font-weight: 390;
    line-height: 32px;
  }
`;
interface Props extends HTMLAttributes<HTMLDivElement> {
  data: MarqueeProps['data'];
  title: string;
  subtitle: string;
  ctaAction: Action;
  secondaryCtaAction?: Action;
  featureCopies?: FeatureCopies[];
}

function divideIntoNSubArrays<T>(arr: T[], n: number) {
  const len = arr.length;
  const out = [];
  let i = 0;
  while (i < len) {
    const size = Math.ceil((len - i) / n--);
    out.push(arr.slice(i, (i += size)));
  }
  return out;
}

const CuratedGifts: FC<Props> = ({
  data: data,
  title = '',
  subtitle = 'Thoughtful. Sustainable. Locally-sourced.',
  ctaAction,
  secondaryCtaAction,
  featureCopies = FEATURE_COPIES,
  ...props
}) => {
  const arr = useMemo(() => divideIntoNSubArrays(data, 3), [data]);

  return (
    <Layout {...props}>
      <Heading>
        <span>{title}</span>
      </Heading>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}

      {/* PRODUCT CAROUSELISH */}
      {arr.map((arr, index) => {
        return <MarqueeV2 key={index} direction={index % 2 === 0 ? 'left' : 'right'} data={arr} />;
      })}

      {/* COPY OF BENEFITS */}
      <Copies>
        {featureCopies.map((feat, index) => (
          <Copy key={index}>
            <img src={feat.img} alt={feat.title + ' image'} />
            <h3 className='title'>{feat.title}</h3>
            <p className='description'>{feat.description}</p>
          </Copy>
        ))}
      </Copies>

      <CtaContainer>
        {ctaAction && <PrimaryCta onClick={ctaAction.onClick}>{ctaAction.name}</PrimaryCta>}
        {secondaryCtaAction && (
          <SecondaryCta onClick={secondaryCtaAction.onClick}>
            {secondaryCtaAction.name}
          </SecondaryCta>
        )}
      </CtaContainer>
      <WaveContainer>
        <div className='block'></div>
        <Img src={wave} alt='wave' />
      </WaveContainer>
    </Layout>
  );
};

export default CuratedGifts;
