import diamond_blinks from './feature-images/diamond_blinks.png';
import hand_hands_gift from './feature-images/hand_hands_gift.png';
import love_shaped_gift from './feature-images/love_shaped_gift.png';
export const feature_data = [
  {
    img: love_shaped_gift,
    title: 'Togetherness. Maximized',
    description:
      'We take the hassle out of long-distance celebration. With our dynamic group cards, you can express any feeling or mark any occasion together, no matter where you are.',
  },
  {
    img: hand_hands_gift,
    title: 'Gifting at your fingertips',
    description:
      'We go above and beyond to source incredible and unique gifts in our gifting marketplace. Guaranteed to make an impression for any occasion.',
  },
  {
    img: diamond_blinks,
    title: 'Saving together by spending together',
    description:
      'With one URL, everyone pitches in just the right amount. Save money and gift as a group.',
  },
];
export const slack_feature_data = [
  {
    img: love_shaped_gift,
    title: 'Togetherness. Maximized',
    description:
      'We take the hassle out of long-distance celebration. With our dynamic group cards, you can express any feeling or mark any occasion together, no matter where you are.',
  },
];
