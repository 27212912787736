import React from 'react';
import dompurify from 'isomorphic-dompurify';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  html: string;
}

const HtmlRenderer = ({ html, ...props }: Props) => {
  return html ? (
    <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(html) }} {...props}></div>
  ) : null;
};

export default HtmlRenderer;
