import { NewSlide } from './ReviewSlider';

import coupleCheers from '../../images/hero-slider-images/couple_cheers.png';

import fourPeople from '../../images/hero-slider-images/four_people.png';
import peopleMeeting from '../../images/hero-slider-images/people_meeting.png';
import personCrossing from '../../images/hero-slider-images/person_crossing.png';
import personLaugh from '../../images/hero-slider-images/person_laugh.png';
import personStareAtLaptop from '../../images/hero-slider-images/person_stare_at_laptop.png';
import techBros from '../../images/hero-slider-images/tech_bros.png';
import threeWomen from '../../images/hero-slider-images/three_women.png';
import twoWomanLaughing from '../../images/hero-slider-images/two_women_laughing.png';
import { USERS } from '../../sections/marketing/data/testimonialsv2';

export const NEW_SLIDE_DATA: NewSlide[] = [
  // removed by request
  // {
  //   image: {
  //     src: coupleCheers,
  //     alt: 'couple cheers',
  //   },
  //   review: {
  //     user: {
  //       avatarSrc: USERS.anne.image,
  //       name: USERS.anne.firstName,
  //     },
  //     copy: 'I was so touched by the thoughtful notes that came with this gift. Thank you so much for thinking of me 💖',
  //   },
  // },
  {
    image: {
      src: fourPeople,
      alt: 'four people',
    },
    review: {
      user: {
        avatarSrc: USERS.ryan.image,
        name: USERS.ryan.firstName,
      },
      copy: 'I was overwhelmed by the kindness and thoughtfulness in the notes that came with this gift. Thank you from the bottom of my heart!',
    },
  },

  {
    image: {
      src: peopleMeeting,
      alt: 'people meeting',
    },
    review: {
      user: {
        avatarSrc: USERS.daniela.image,
        name: USERS.daniela.firstName,
      },
      copy: 'The gift was amazing, but what really stood out to me were the heartfelt notes that came with it. Thank you so much 🙏',
    },
  },

  {
    image: {
      src: personCrossing,
      alt: 'person crossing',
    },
    review: {
      user: {
        avatarSrc: USERS.ryan.image,
        name: USERS.ryan.firstName,
      },
      copy: 'I was so touched by the thought and care that went into the notes that came with this gift. Thank you for making my day!',
    },
  },

  {
    image: {
      src: personLaugh,
      alt: 'person laugh',
    },
    review: {
      user: {
        avatarSrc: USERS.ryan.image,
        name: USERS.ryan.firstName,
      },
      copy: 'Thank you so much for coordinating this! What a wonderful and meaningful present :)',
    },
  },

  {
    image: {
      src: personStareAtLaptop,
      alt: 'person stare at laptop',
    },
    review: {
      user: {
        avatarSrc: USERS.daniela.image,
        name: USERS.daniela.firstName,
      },
      copy: 'I am blown away by the love and thoughtfulness that went into the notes that accompanied this gift. Thank you so much 💕',
    },
  },

  {
    image: {
      src: techBros,
      alt: 'tech bros',
    },
    review: {
      user: {
        avatarSrc: USERS.tanya.image,
        name: USERS.tanya.firstName,
      },
      copy: 'The gift was amazing, but what really made it special were the personal notes that came with it. Thank you for making my day!',
    },
  },

  {
    image: {
      src: threeWomen,
      alt: 'Three woman',
    },
    review: {
      user: {
        avatarSrc: USERS.daniela.image,
        name: USERS.daniela.firstName,
      },
      copy: 'I am blown away by the love and thoughtfulness that went into the notes that accompanied this gift. Thank you so much 💕',
    },
  },
  {
    image: {
      src: twoWomanLaughing,
      alt: 'Two Person Laughing',
    },
    review: {
      user: {
        avatarSrc: USERS.tanya.image,
        name: USERS.tanya.firstName,
      },
      copy: 'Just got this glass french press from my team as a farewell gift. What a nice idea, thanks guys!! ',
    },
  },
];
