import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import LinkButton from '../../components/LinkButton';
import OrbContainer from '../../components/OrbContainer';
import ReviewsSlider from '../../components/reviewSlider/ReviewSlider';
import { EXPLORE_GIFTS, SEND_FREE_GROUPCARD_LINK, SLACK_INSTALL_LINK } from '../../constants/links';
import useFormFactors from '../../helpers/useFormFactors';
import blob from '../../images/blobs/blob.png';
import { theme } from '../../styles/theme';
import useMeaningfulGroupGifts from '../common/hooks/useMeaningfullGroupGifts';
import WordSlider from './components/WordSlider';
import { getMainAppFullPath } from '../../constants/getUrl';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
  padding-top: 40px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 24px 16px;
    padding-top: 80px;
    max-width: initial;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 40px 0;
    // padding-top: 100px;
    max-width: initial;
  }
`;

const SectionLeft = styled.div`
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 40%;
  }
`;

const SectionRight = styled.div`
  position: relative;
  width: 100%;
  z-index: 4;
  @media (min-width: 768px) {
    z-index: 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 60%;
  }
`;

const Body = styled.div`
  padding: 24px 5px;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 24px 46px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 0 0 86px;
    padding-top: 80px;
  }
`;

const Heading = styled.p<{ wrap: boolean }>`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: center;
  max-width: 529px;
  white-space: ${(props) => (props.wrap ? 'normal' : 'nowrap')};

  @media only screen and (min-width: 220px) and (max-width: 368px) {
    font-size: 32px;
    max-width: 240px;
    text-align: center;
  }

  @media only screen and (min-width: 369px) and (max-width: 414px) {
    font-size: 32px;
    max-width: 240px;
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
    max-width: 529px;
    line-height: 70px;
    font-size: 64px;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 26px;
  line-height: 22px;
  margin: 0 0 16px 0;
  max-width: 480px;
  text-align: center;

  em strong {
    font-weight: 500;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 0 0;
    text-align: left;
    max-width: 325px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
  }
`;

const GroupGiftLink = styled(LinkButton)`
  margin: 0 0 8px 0;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 18px 15px 0 0;
    max-width: 184px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 18px 15px 0 0;
    max-width: 240px;
  }
  &:hover {
    background-color: #e5a619;
  }
`;

const SlackGroupGiftLink = styled(LinkButton)`
  margin: 0 0 8px 0;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.black};

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 18px 15px 0 0;
    max-width: 200px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 18px 15px 0 0;
    max-width: 200px;
  }
  &:hover {
    background-color: #e5a619;
  }
`;

const ExploreGiftsLink = styled(LinkButton)`
  background: ${(props) => props.theme.colors.lightPeach};
  color: ${(props) => props.theme.colors.blackText};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  &:hover {
    background-color: #d9d2c2;
  }
  transition: background-color 0.2s ease-in-out;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 18px 0 0 0;
    max-width: 136px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 18px 0 0 0;
    max-width: 176px;
  }
`;

const Ticker = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 12px 0 0 0;
  width: 100%;

  strong {
    padding: 0 4px 0 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 240px;
  }
`;

const CreditCardText = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 12px 0 0 0;
  width: 100%;

  strong {
    padding: 0 4px 0 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 240px;
    justify-content: flex-start;
    margin-left: 15px;
  }
`;

const Blob = styled.img`
  height: 800px;
  left: -219px;
  position: absolute;
  top: 0;
  width: 800px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 746px;
    left: -219px;
    position: absolute;
    top: -22px;
    width: 764px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -95px;
    position: absolute;
    top: 235px;
    width: 800px;
  }
`;

const ReviewSliderWrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.laptop} {
    .swiper {
      position: relative;
      width: 1000px;
      max-width: none !important;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
`;
interface Props {
  flippingTexts: string[];
  headline?: string;
  flippedTextDirection?: 'left' | 'right';
  wrap?: boolean;
  showCta?: boolean;
  showCount?: boolean;
}

const MeaningfullGroupGifts: FC<Props> = ({
  flippingTexts,
  headline = 'Meaningful and unique group gifts for ',
  flippedTextDirection = 'left',
  wrap = false,
  showCta = true,
  showCount = true,
}) => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const notesRef = useRef<HTMLElement>(null);

  const formFactors = useFormFactors();

  const { numberOfNotes } = useMeaningfulGroupGifts({ notesRef });

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <SectionLeft style={{ zIndex: 3 }}>
        <Body>
          <Heading wrap={wrap}>
            {flippedTextDirection === 'left' ? (
              <>
                <span> {headline}</span>
                <WordSlider
                  color={theme.colors.orange}
                  rotationSpeed={3000}
                  words={flippingTexts}
                />
              </>
            ) : (
              <>
                <WordSlider
                  color={theme.colors.orange}
                  rotationSpeed={3000}
                  words={flippingTexts}
                />
                <span> {headline}</span>
              </>
            )}
          </Heading>
          <Paragraph>
            From group cards to group gifts, we simplify the logistics so you can celebrate, thank,
            and support a recipient – no matter where you are. illume is your celebration platform
          </Paragraph>
          {showCta && (
            <Actions>
              <GroupGiftLink href={getMainAppFullPath('/create/info')}>
                Create a group card
              </GroupGiftLink>
              <ExploreGiftsLink href={EXPLORE_GIFTS}>Explore all gifts</ExploreGiftsLink>
            </Actions>
          )}
          {showCount && (
            <Ticker>
              <strong>{numberOfNotes}</strong> notes sent with love &#10084;&#65039;
            </Ticker>
          )}
        </Body>
      </SectionLeft>
      <SectionRight>
        {!formFactors.mobile && <Title>The Group Celebration Platform</Title>}
        <ReviewSliderWrapper>
          <ReviewsSlider showReviews={!formFactors.mobile} showCta={formFactors.mobile} />
        </ReviewSliderWrapper>
      </SectionRight>
    </Layout>
  );
};

export const SlackMeaningfullGroupGifts: FC<Props> = ({
  flippingTexts,
  headline = 'Meaningful and unique group gifts for ',
  flippedTextDirection = 'left',
  wrap = false,
  showCta = true,
  showCount = true,
}) => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const notesRef = useRef<HTMLElement>(null);

  const formFactors = useFormFactors();

  const { numberOfNotes } = useMeaningfulGroupGifts({ notesRef });

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <SectionLeft style={{ zIndex: 3 }}>
        <Body>
          <Heading wrap={wrap}>
            {flippedTextDirection === 'left' ? (
              <>
                <span> {headline}</span>
                <WordSlider
                  color={theme.colors.orange}
                  rotationSpeed={3000}
                  words={flippingTexts}
                />
              </>
            ) : (
              <>
                <WordSlider
                  color={theme.colors.orange}
                  rotationSpeed={3000}
                  words={flippingTexts}
                />
                <span> {headline}</span>
              </>
            )}
          </Heading>
          <Paragraph>
            We simplify the logistics so you can celebrate, thank, and support a recipient – no
            matter where you are. illume is your celebration platform.
          </Paragraph>
          {showCta && (
            <Actions>
              <SlackGroupGiftLink href={SLACK_INSTALL_LINK}>
                <svg
                  style={{ marginRight: 10 }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='24' height='24' rx='6' fill='white' />
                  <path
                    d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M9.63735 6.77098C8.58153 6.77098 7.75195 5.91623 7.75195 4.88549C7.75195 3.85476 8.60666 3 9.63735 3C10.668 3 11.5227 3.85476 11.5227 4.88549V6.77098H9.63735Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M9.63658 7.72607C10.6924 7.72607 11.522 8.58083 11.522 9.61157C11.522 10.6423 10.6673 11.4971 9.63658 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.63658Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M16.2747 9.63693C16.2747 10.6928 15.42 11.5224 14.3893 11.5224C13.3586 11.5224 12.5039 10.6677 12.5039 9.63693V4.88549C12.5039 3.82962 13.3586 3 14.3893 3C15.42 3 16.2747 3.85476 16.2747 4.88549V9.63693Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3332 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                    fill='#E01E5A'
                  />
                  <path
                    d='M9.61196 6.77098C8.55614 6.77098 7.72656 5.91623 7.72656 4.88549C7.72656 3.85476 8.58127 3 9.61196 3C10.6426 3 11.4973 3.85476 11.4973 4.88549V6.77098H9.61196Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M9.61144 7.72607C10.6673 7.72607 11.4968 8.58083 11.4968 9.61157C11.4968 10.6423 10.6421 11.4971 9.61144 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.61144Z'
                    fill='#36C5F0'
                  />
                  <path
                    d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M16.2493 9.63693C16.2493 10.6928 15.3946 11.5224 14.3639 11.5224C13.3081 11.5224 12.4785 10.6677 12.4785 9.63693V4.88549C12.4785 3.82962 13.3332 3 14.3639 3C15.4197 3 16.2493 3.85476 16.2493 4.88549V9.63693Z'
                    fill='#2EB67D'
                  />
                  <path
                    d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3081 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                    fill='#ECB22E'
                  />
                  <path
                    d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                    fill='#ECB22E'
                  />
                </svg>
                Try illume For Free
              </SlackGroupGiftLink>
              {/* <ExploreGiftsLink href={EXPLORE_GIFTS}>Explore all gifts</ExploreGiftsLink> */}
            </Actions>
          )}
          {showCount && <CreditCardText>No credit card required</CreditCardText>}
        </Body>
      </SectionLeft>
      <SectionRight>
        {!formFactors.mobile && <Title>The Group Celebration Platform</Title>}
        <ReviewSliderWrapper>
          <ReviewsSlider showReviews={!formFactors.mobile} showCta={formFactors.mobile} isSlack />
        </ReviewSliderWrapper>
      </SectionRight>
    </Layout>
  );
};

export default MeaningfullGroupGifts;
